import React from 'react';

import { getMessages } from '../locales';

import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import useAnalytics from '../hooks/useAnalytics';

import parse from 'html-react-parser';

function SmsTermsAndConditionsPage() {
  const messages = getMessages();

  return (
    <Layout>
      <div className='landing-container'>
        <SEO metadata={{ title: config.termsAndConditionsTitle }} />
        <section className='terms-and-conditions page'>
          <div className='container'>
            <h3 className='page-title'>{messages['termsAndConditions.title']}</h3>
            <p>{parse(messages['termsAndConditions'])}</p>
          </div>
        </section>
      </div>
    </Layout>
  )
};

export default SmsTermsAndConditionsPage;
